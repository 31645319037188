import React from "react";
import thanksImg from "../assets/thanks.gif";

const ThankYouPage = () => {
  return (
    <>
      <div className="custom_body">
        <div className="welcome">
          <img src={thanksImg} alt="" />
          <h2>Thanks for using PixMeet</h2>
        </div>
      </div>
      <div className="footer">
        <p>Allrights reserved by pixfar team</p>
      </div>
    </>
  );
};

export default ThankYouPage;
