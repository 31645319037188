import React, { useContext, useState } from "react";
import { Calendar, Link2, Plus } from "react-feather";
import { PiVideoCameraThin } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import image from "../assets/avatar.png";
import bannerImg from "../assets/banner.gif";
import logo from "../assets/logo.png";
import Modal from "../components/Modal";
import { MeetContext } from "../context/MeetContext";
import { generateString } from "../helper/generateRandomString";

// Alert when the user hasn't filled up their name

const StartupPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [showTopicError, setShowTopicError] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showJoinError, setShowJoinError] = useState(false);
  const [joinMeetId, setJoinMeetId] = useState("");
  const [topic, setTopic] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [startShow, setStartShow] = useState(false);

  // we will use this to navigate next page
  const history = useHistory();

  /// button style
  const buttonStyle = {
    width: "100%",
    backgroundColor: "#008eff",
    padding: ".5rem 0",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    margin: ".5rem 0",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#006fcc",
    },
  };

  // will be using name across all pages from context
  const [name, setName] = useContext(MeetContext);

  const handleCreateMeet = () => {
    // setShowOptionModal(true);
    setStartShow(!startShow);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    setShowError(false);
    setName(e.target.value);
  };

  const instatMeeting = () => {
    // first close the option modal
    setShowOptionModal(false);

    // check if the user has entered the name
    const name = localStorage.getItem("name");
    if (!name) {
      setShowModal(true);
      return;
    }
    history.push(`/meet/${generateString(4)}`);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // first set the name in local storage
    localStorage.setItem("name", name);

    if (name === "") {
      setShowError(true);
      return;
    }

    if (joinMeetId) {
      const meetId = joinMeetId.split("/")[joinMeetId.split("/").length - 1];
      history.push(`/meet/${meetId}`);
      return;
    }

    history.push(`/meet/${generateString(4)}`);
  };

  const handleTopic = () => {
    // first close the option modal
    setShowOptionModal(false);

    // check if the user has entered the name
    setShowTopicModal(true);
  };

  const handleOptionModalClose = () => {
    setShowOptionModal(false);
  };

  const handleTopicModalClose = () => {
    setShowTopicModal(false);
  };

  const handleTopicInputChange = (e) => {
    setShowTopicError(false);
    setTopic(e.target.value);
  };

  const handleTopicFormSubmit = (e) => {
    e.preventDefault();

    if (topic === "") {
      setShowTopicError(true);
      return;
    }

    const name = localStorage.getItem("name");
    if (!name) {
      setShowTopicModal(false);
      setShowModal(true);
      return;
    }

    history.push(`/meet/${topic}`);
  };

  const handleJoinMeet = () => {
    setShowJoinModal(true);
  };

  const handleJoinModalClose = () => {
    setShowJoinModal(false);
  };

  const handleJoinInputChange = (e) => {
    setShowJoinError(false);
    setJoinMeetId(e.target.value);
  };

  const handleJoinFormSubmit = (e) => {
    e.preventDefault();

    if (joinMeetId === "") {
      setShowJoinError(true);
      return;
    }

    const name = localStorage.getItem("name");
    if (!name) {
      setShowJoinModal(false);
      setShowModal(true);
      return;
    }
    const meetId = joinMeetId.split("/")[joinMeetId.split("/").length - 1];

    if (!meetId) {
      setShowJoinError(true);
      return;
    }

    history.push(`/meet/${meetId}`);
  };

  console.log(displayName);

  return (
    <>
      <div className="mainHeader">
        <div className="header">
          <div className="logo">
            <img src={logo} alt="" />
            <h1>ixClub</h1>
          </div>

          <div className="header_right">
            <h2>{displayName}</h2>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
      <div className="custom_body">
        <div className="main_body">
          <div className="all_button">
            <h2>Premium video meetings. Now free for everyone.</h2>
            <p>
              We re-engineered the service we built for secure business
              meetings, Google Meet, to make it free and available for all.
            </p>
            <div className="body_button" style={{ position: "relative" }}>
              <button className="bg_primary" onClick={handleCreateMeet}>
                <PiVideoCameraThin size={25} /> Create a meetting
              </button>
              {startShow && (
                <div
                  style={{
                    width: "300px",
                    position: "absolute",
                    backgroundColor: "white",
                    borderWidth: "1px",
                    borderColor: "#ddd",
                    borderStyle: "solid",
                    // padding: 20,
                    borderRadius: "5px",
                    top: "100%",
                  }}
                >
                  <ul>
                    <li>
                      <button
                        style={{
                          width: "100%",
                          textAlign: "left",
                          backgroundColor: "white",
                          padding: "15px 20px",
                          fontSize: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <Link2 size={20} /> Create a meeting for later
                      </button>
                    </li>
                    <li>
                      <button
                        style={{
                          width: "100%",
                          textAlign: "left",
                          backgroundColor: "white",
                          padding: "15px 20px",
                          fontSize: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                        onClick={instatMeeting}
                      >
                        <Plus size={20} /> Start an instant meeting
                      </button>
                    </li>
                    <li>
                      <button
                        style={{
                          width: "100%",
                          textAlign: "left",
                          backgroundColor: "white",
                          padding: "15px 20px",
                          fontSize: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                        onClick={handleTopic}
                      >
                        <Calendar size={20} /> Schedule in Google Calendar
                      </button>
                    </li>
                  </ul>
                </div>
              )}
              {/* <button onClick={handleJoinMeet}>Join a Link</button> */}
              <p>-OR-</p>
              <form onSubmit={handleJoinFormSubmit} className="join_group">
                <input
                  type="text"
                  id="url"
                  name="url"
                  value={joinMeetId}
                  onChange={handleJoinInputChange}
                  required
                  placeholder="Enter a meeting link"
                />
                <button
                  disabled={joinMeetId === ""}
                  style={{ backgroundColor: joinMeetId === "" && "#328eff9d" }}
                >
                  Join
                </button>
              </form>
            </div>
          </div>
          <div className="banner">
            <img src={bannerImg} alt="banner" />
          </div>
        </div>
      </div>
      <div className="footer">
        <p>Allergists reserved by pixfar team</p>
      </div>
      {showModal && (
        <Modal handleModalClose={handleModalClose} title="Create meetting">
          <form onSubmit={handleFormSubmit} style={{ paddingTop: "10px" }}>
            <div class="form-group">
              <label for="name">Display Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={handleInputChange}
                required
              />

              {showError && (
                <span class="error-message">Please enter a display name</span>
              )}
            </div>
            <button type="submit" class="submit-button">
              Submit
            </button>
          </form>
        </Modal>
      )}
      {showOptionModal && (
        <Modal
          handleModalClose={handleOptionModalClose}
          title="Create meetting"
        >
          <button style={buttonStyle} onClick={instatMeeting}>
            Start Instant Meeting
          </button>
          <button style={buttonStyle}>Start Scheduled Meeting</button>
          <button style={buttonStyle} onClick={handleTopic}>
            Create Meeting Topic
          </button>
        </Modal>
      )}
      {showTopicModal && (
        <Modal handleModalClose={handleTopicModalClose} title="Create meetting">
          <form onSubmit={handleTopicFormSubmit} style={{ paddingTop: "10px" }}>
            <div class="form-group">
              <label for="name">Topic Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={topic}
                onChange={handleTopicInputChange}
                required
              />

              {showTopicError && (
                <span class="error-message">Please enter a topic title</span>
              )}
            </div>
            <button type="submit" class="submit-button">
              Submit
            </button>
          </form>
        </Modal>
      )}
      {showJoinModal && (
        <Modal handleModalClose={handleJoinModalClose} title="Create meetting">
          <form onSubmit={handleJoinFormSubmit} style={{ paddingTop: "10px" }}>
            <div class="form-group">
              <label htmlFor="url">Meeting Url</label>
              <input
                type="text"
                id="url"
                name="url"
                value={joinMeetId}
                onChange={handleJoinInputChange}
                required
              />

              {showJoinError && (
                <span class="error-message">
                  Please enter a valid meeting url
                </span>
              )}
            </div>
            <button type="submit" class="submit-button">
              Submit
            </button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default StartupPage;
