const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

// this will help us generate random string for room name
export function generateString(length) {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result +=
      "pix-" + characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result.trim().toLowerCase();
}
