import React from "react";
import classes from "./modal.module.css";

function Modal({ handleModalClose, title, children }) {
  return (
    <div id="my-modal" className={classes.modal}>
      <div className={classes.modal_content}>
        <span className={classes.close} onClick={handleModalClose}>
          &times;
        </span>
        {title && <h3 className={classes.modal_title}>{title}</h3>}
        {children}
      </div>
    </div>
  );
}

export default Modal;
