import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

const MeetPage = ({ match }) => {
  const height = window.innerHeight;
  // const [name, setName] = useState("");

  //AS OF NOW DOMAIN WOULD BE JITSI'S AS WE ARE STILL USING THIER SERVERS
  const domain = "meet.pixfar.com";
  // const domain = "8x8.vc";
  let api = {};

  const history = useHistory();

  // useEffect(() => {
  //   const string = JSON.parse(localStorage.getItem("userName"));
  //   setName(string);
  // }, []);

  // THIS IS TO EXTRACT THE NAME WHICH WAS FILLED IN THE FIRST PAGE
  // const [name] = useContext(MeetContext);

  // INTIALISE THE MEET WITH THIS FUNCTION
  const startMeet = useCallback(() => {
    const options = {
      // SHOW_JITSI_WATERMARK: false,
      // HIDE_DEEP_LINKING_LOGO: true,
      // SHOW_BRAND_WATERMARK: false,
      // SHOW_WATERMARK_FOR_GUESTS: false,
      roomName: match.params.id,
      width: "100%",
      height: height - 10,
      configOverwrite: {
        toolbarButtons: [
          "microphone",
          "camera",
          "fullscreen",
          "hangup",
          "profile",
          "chat",
          "etherpad",
          "sharedvideo",
          "shareaudio",
          "settings",
          "raisehand",
          "videoquality",
          "filmstrip",
          "stats",
          "tileview",
          "select-background",
          "mute-everyone",
          "mute-video-everyone",
          "security",
          "whiteboard",
        ],
        //   toolbarButtons: [
        //   'microphone', 'camera','fullscreen','hangup','profile','videoquality'
        // ],
      },

      interfaceConfigOverwrite: {},
      // VIDEO FRAME WILL BE ADDED HERE
      parentNode: document.querySelector("#jitsi-iframe"),
      // userInfo: {
      //   displayName: name,
      // },
      // onload: () => {
      //   const jlogo = document.querySelectorAll(".watermark");
      //   console.log(jlogo);
      // },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    api = new window.JitsiMeetExternalAPI(domain, options);

    api.addEventListeners({
      readyToClose: handleClose,
      participantLeft: handleParticipantLeft,
      participantJoined: handleParticipantJoined,
      videoConferenceJoined: handleVideoConferenceJoined,
      videoConferenceLeft: handleVideoConferenceLeft,
    });
  }, [api]);

  useEffect(() => {
    if (window.JitsiMeetExternalAPI) {
      startMeet();
    } else {
      alert("JitsiMeetExternalAPI not loaded");
    }
  }, [startMeet]);

  // ALL OUR HANDLERS
  const handleClose = () => {
    console.log("handleClose");
  };

  const handleParticipantLeft = async (participant) => {
    console.log("handleParticipantLeft", participant);
    await getParticipants();
  };

  const handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant);
    await getParticipants();
  };

  const handleVideoConferenceJoined = async (participant) => {
    console.log("handleVideoConferenceJoined", participant);
    await getParticipants();
  };

  const handleVideoConferenceLeft = () => {
    console.log("handleVideoConferenceLeft");
    history.push("/");
  };

  // GETTING ALL PARTICIPANTS
  const getParticipants = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(api.getParticipantsInfo());
      }, 500);
    });
  };

  return (
    <React.Fragment>
      <div id="jitsi-iframe" style={{ marginBottom: 0 }}></div>
      <div
        className="footer"
        style={{
          // height: "20vh",
          backgroundColor: "#040404",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <p>Allrights reserved by pixfar team</p>
      </div>
    </React.Fragment>
  );
};

export default MeetPage;
